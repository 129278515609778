import { useEffect, useRef } from 'react';
import { useTrack } from 'src/contexts';
import { BOT_EVENTS } from 'src/lib/trackingEvents';

const useBotCheckEvent = () => {
  const hasTracked = useRef(false);
  const hasInteraction = useRef(false);
  const track = useTrack();

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const doTrack = () => {
      if (hasTracked.current) return;
      track({
        type: BOT_EVENTS.SUSPECTED_BOT_SESSION,
        payload: {
          webdriver: window?.navigator?.webdriver,
          userAgent: window?.navigator?.userAgent,
        },
      });
      hasTracked.current = true;
    };

    let isBot = false;

    // webdriver check (headless chrome)
    // various user agent checks
    if (window?.navigator?.webdriver === true) {
      isBot = true;
    } else if (
      /PhantomJS|Headless|Electron|slimerj|Googlebot|AdsBot|bingbot|HubSpot|AppEngine|uptime|Lighthouse|duckduckbot|Baiduspider|Yandex/i.test(
        window?.navigator?.userAgent || '',
      )
    ) {
      // user agent check
      isBot = true;
    }

    // if any of our static checks are true, assume bot and track.
    if (isBot) {
      doTrack();
      return;
    }

    // check for events (mouse move, keyboard, etc.)
    const eventTrigger = () => {
      hasInteraction.current = true;
      removeEvents();
    };

    // if static checks failed, lets wire up some events to check for interactivity
    // also add unload event to trigger tracking
    const eventTriggers = [
      'mousemove',
      'keypress',
      'scroll',
      'touchstart',
      'touchmove',
      'pointermove',
      'pointerdown',
    ];

    const beforeUnload = () => {
      if (!hasInteraction.current) {
        doTrack();
      }
    };

    const addEvents = () => {
      eventTriggers.forEach((e) => {
        // setup listener events to be passive - we dont want them blocking usability
        window.addEventListener(e, eventTrigger, { passive: true });
      });
      window.addEventListener('beforeunload', beforeUnload);
    };

    const removeEvents = () => {
      eventTriggers.forEach((e) => {
        window.removeEventListener(e, eventTrigger);
      });
      window.removeEventListener('beforeunload', beforeUnload);
    };

    // wireup the default events
    addEvents();

    // wiere up a timeout (if the user sits on the page for more than X seconds, lets assume not a bot)
    // try and limit false positives
    const eventTimeout = setTimeout(() => {
      removeEvents();
    }, 5000);

    return () => {
      if (eventTimeout) {
        clearTimeout(eventTimeout);
      }
      removeEvents();
    };
  }, [track]);
};

export default useBotCheckEvent;

export const BotCheckEvent = () => {
  useBotCheckEvent();
  return <></>;
};
